import { IAccessControl } from "./spi";
const msalBrowser = await import("@azure/msal-browser");


export class MSAL implements IAccessControl {
    private client;
    private accountInfo;

    constructor(client, accountInfo) {
        this.client = client;
        this.accountInfo = accountInfo;
    }

    async getToken(): Promise<string> {
        const request = {
            scopes: [`api://${process.env.REACT_APP_ENTRA_ID_CLIENT_ID}/user_impersonation`],
            account: this.accountInfo
        };
        return await this.client.acquireTokenSilent(request).then(tokenResponse => {
            return tokenResponse.accessToken;
        }).catch(async (error) => {
            if (error instanceof msalBrowser.InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return this.client.acquireTokenRedirect(request).then((data) => data.accessToken);
            }
            console.log(error);
        })
    }
}