import { useIsAuthenticated } from "@azure/msal-react";
import { Result } from "antd";
import { Link } from "react-router-dom";

export function SecuredComponent({children}: {children: React.ReactNode}) {
    const isAuthenticated = useIsAuthenticated();
    if (isAuthenticated) {
        return <>{children}</>;
    }
    return <Result
        status="403"
        title="403"
        subTitle="You are not authorized to visit this page. If you need access, please contact LDD Platform Team."
        extra={<Link to={process.env.REACT_APP_TEAM_CONTACT_CHAT_URL || ""}>Contact</Link>}
    />;
}