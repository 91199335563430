import { Menu } from "antd";
import { ApplicationKey } from "../contexts/ApplicationContext";
import { BuildTwoTone, RocketTwoTone } from "@ant-design/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";


export const PREVIOUS_PAGE_STORAGE_KEY = "prev-page";

export const MenuLDDProVersionManagementChoice = {
    label: 'LDDPro Version Management',
    key: "version-management",
    application: ApplicationKey.legoDigitalDesigner,
    icon: <BuildTwoTone/>,
};

export const MenuLDDLauncherChoice = {
    label: 'LDD Launcher',
    key: ApplicationKey.lddlauncher,
    application: ApplicationKey.lddlauncher,
    icon: <RocketTwoTone/>,
};

export function TopMenu() {
    const navigate = useNavigate();
    const defaultChoice = localStorage.getItem(PREVIOUS_PAGE_STORAGE_KEY) || MenuLDDLauncherChoice.key
    const [current, setCurrent] = useState(defaultChoice);

    const onClick = (e: any) => {
        navigate(e.key);
        setCurrent(e.key);
        localStorage.setItem(PREVIOUS_PAGE_STORAGE_KEY, e.key);
    }
    return (
        <Menu
        onClick={onClick}
        theme="dark"
        selectedKeys={[current]}
        mode="horizontal"
        style={{ minWidth: 0, flex: "auto" }}
        items={[MenuLDDProVersionManagementChoice, MenuLDDLauncherChoice]} 
        />
    );
}