import React from "react";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Breadcrumb, Layout, theme } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { Link, Outlet, useLocation, useNavigation } from "react-router-dom";
import { TopMenu } from "./TopMenu";
import LoadingAnimation from "../components/Loading";

export default function CoreLayout() {
  const location = useLocation();
  const { state } = useNavigation();

  if (state === 'loading') {
    return LoadingAnimation;
  }
  const pathSnippets = location.pathname.split('/').filter((i) => i);

  const breadcrumbItems = pathSnippets
      .map((resource, index) => {
          const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
          return {
              key: url,
              title: <Link to={url}>{resource}</Link>,
          };
      }).filter((_, index) => index > 0);

  const {
      token: { colorBgContainer },
    } = theme.useToken();

  let content = (
    <div className="site-layout-content" style={{ background: colorBgContainer, width: '75%', margin: 'auto'}}>
      <Outlet />
    </div>
  );
  return (
    <Layout className="layout" style={{height: "100vh"}}>
      <Header style={{ display: 'flex', alignItems: 'center' }}>
        <TopMenu></TopMenu>
        <Avatar style={{ backgroundColor: '#1890ff', marginLeft: "auto" }} icon={<UserOutlined />}/>
      </Header>
      <Content className="site-layout" style={{ padding: '0 50px' }}>
        <Breadcrumb items={breadcrumbItems} style={{ margin: '10px'}}/>
        {content}
      </Content>
      <Footer style={{ textAlign: 'center' }}>LDD Platform</Footer>
    </Layout>
  );
}