import { AppleOutlined, CloseOutlined, WindowsOutlined } from "@ant-design/icons"
import { OS } from "@ffld/domain"

export function IconPlatform({platform}: {platform: OS}) {
    switch (platform) {
        case "windows":
            return <WindowsOutlined />
        case "darwin":
            return <AppleOutlined />
        default:
            return <CloseOutlined />
    }
}