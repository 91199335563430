import { ArtifactRestAdapter } from "@ffld/adapters";
import { MSAL } from "@ffld/access-control";
import { Application, IApplicationAPI } from "@ffld/domain";

namespace ApplicationContainer {
    let artifactAdapter: ArtifactRestAdapter;
    let applicationAPI: IApplicationAPI;

    export function createApplicationAPI(clientInstance: any, accountInfo: any): IApplicationAPI {
        const msal = new MSAL(clientInstance, accountInfo);
        artifactAdapter = new ArtifactRestAdapter(msal);
        applicationAPI = new Application(artifactAdapter);
        return applicationAPI;
    }

    
    export function getApplicationAPI(): IApplicationAPI {
        return applicationAPI;
    }
}

export { ApplicationContainer };