import { IApplicationAPI, EnvironmentVersions } from "./ports/api"
import { IArtifactAdapter } from "./ports/spi";
import { ReleaseStatus } from "./types/artifact-status";
import { Environment } from "./types/environment";
import { ArtifactType, Flavor, OS, Version } from "./types/version";


class Application implements IApplicationAPI {
    private artifactService: IArtifactAdapter;

    constructor(artifactService: IArtifactAdapter) {
        this.artifactService = artifactService;
    }

    async getApplicationEnvironments(applicationName: string): Promise<Environment[]> {
        const environmentNames = await this.artifactService.getApplicationEnvironmentNames(applicationName);
        return environmentNames.map((name) => {
            return {name: name, applicationName: applicationName};
        });
    }

    async getEnvironmentVersions(environment: Environment): Promise<EnvironmentVersions> {
        const versions = await this.artifactService.getEnvironmentVersions(environment);
        const latestVersionName = await this.artifactService.getLatestVersion(environment);
        return {
            latestVersionName: latestVersionName,
            versions: versions,
        }
    }

    async getVersionDownloadLink(environment: Environment, version: string, flavor: Flavor, os: OS, artifactType: ArtifactType): Promise<string> {
        return await this.artifactService.getVersionDownloadLink(environment, version, flavor, os, artifactType)
    }

    async updateVersionReleaseStatus(environment: Environment, version: string, newStatus: ReleaseStatus): Promise<void> {
        return await Promise.all([
            this.artifactService.updateArtifact(environment, version, Flavor.PRO, newStatus),
            this.artifactService.updateArtifact(environment, version, Flavor.PARTNER, newStatus),
        ]).then(() => Promise.resolve());
    }

    getLastActionPerformed(version: Version): string {
        if (version.history?.length > 0 ) {
            return version.history[version.history.length - 1]!.action;
        }
        return "No Action";
    }
}

export { Application }