import Checkbox, { CheckboxChangeEvent } from "antd/es/checkbox";
import { filterVersionsByStatus } from "../../actions/application-version";
import { ReleaseStatus } from "@ffld/domain";
import { State } from "../../views/ApplicationVersions.state";


export function VersionStatusFilter(
    {
        statusToFilter,
        versionsState,
        dispatch
    }:{
        statusToFilter: ReleaseStatus,
        versionsState: State,
        dispatch: React.Dispatch<any>
    }) {
    const handleCheckboxChange = (e: CheckboxChangeEvent) => {
        filterVersionsByStatus(statusToFilter, e.target.checked)(dispatch);
    }
    return (
        <Checkbox onChange={handleCheckboxChange} checked={versionsState.filters.includes(statusToFilter)}>
            {statusToFilter.toLocaleUpperCase()}
        </Checkbox>
    )
}