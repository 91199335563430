import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react"


export const useEffectWithMSAL = (callback: () => any) => {
    const { accounts, inProgress } = useMsal();
    useEffect(() => {
        if (inProgress === InteractionStatus.None && accounts.length > 0) {
            callback();
        } else {
            return;
        }
    }, [inProgress, accounts, callback])
}