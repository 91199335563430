import lddproLogo from '../assets/lddproLogo.png';
import lddpartnerLogo from '../assets/lddpartnerLogo.png';
import { useParams } from 'react-router-dom';
import { Avatar, Space, Table, Button } from 'antd';
import { WindowsOutlined, AppleOutlined, CloseOutlined } from '@ant-design/icons';
import Column from 'antd/es/table/Column';
import { ArtifactType, Flavor, OS, Version } from '@ffld/domain';
import { ApplicationContainer } from '@ffld/loader';
import { ApplicationKey } from '../contexts/ApplicationContext';
import React, { useState, useCallback } from 'react';
import { loadVersions } from '../loaders/versions';
import { useEffectWithMSAL } from '../hooks/useEffectWithMSAL';


function IconPlatform({platform}: {platform: OS}) {
    switch (platform) {
        case "windows":
            return <WindowsOutlined />
        case "darwin":
            return <AppleOutlined />
        default:
            return <CloseOutlined />
    }
}

export function EnvironmentLauncherVersionsPage({applicationName}: {applicationName: string}) {
    const { environmentName } = useParams();
    const [versions, setVersions] = useState<Version[]>([]);

    const loadData = useCallback(async () => {
        const versionsData = await loadVersions({applicationName: applicationName, environmentName: environmentName || ""})
        setVersions(versionsData.versions);
    }, [applicationName, environmentName]);

    useEffectWithMSAL(loadData);
    return <VersionTable versions={versions}/>;
}


function DownloadButton(
    {version, flavor, platform}
    : {version: string, flavor: Flavor, platform: OS}
) {
    const params = useParams() as {environmentName: string};
    return (
        <Button type="primary"
            icon={<IconPlatform platform={platform}/>}
            size="large"
            onClick={async () => {
                const binaryDownloadLink = await ApplicationContainer.getApplicationAPI().getVersionDownloadLink(
                    {name: params.environmentName, applicationName: ApplicationKey.lddlauncher},
                    version, flavor, platform, ArtifactType.EXECUTABLE
                );
                window.location.href = binaryDownloadLink;

                const debugFilesDownloadLink = await ApplicationContainer.getApplicationAPI().getVersionDownloadLink(
                    {name: params.environmentName, applicationName: ApplicationKey.lddlauncher},
                    version, flavor, platform, ArtifactType.DEBUG
                );
                window.location.href = debugFilesDownloadLink;
            }}
        />
    )
}

function VersionTable({versions}: {versions: Version[]}) {
    return (
        <Table dataSource={versions}>
            <Column title="Version" dataIndex="name" key="name" />
            <Column 
                title="Published At"
                dataIndex="publishedAt"
                key="publishedAt"
                defaultSortOrder="descend"
                sorter={(a: Version, b: Version) => a.publishedAt.getTime() - b.publishedAt.getTime()}
                sortDirections={['descend']}
                render={(date: Date) => date.toISOString() }
            />
            <Column title="Commit Sha" dataIndex="sha" key="sha" />
            <Column title={
                <Space size="small">
                    <Avatar src={lddproLogo}/>
                    Pro link
                </Space>
            } key="lddProDownload"
                render={(_, record: Version) => (
                    <Space size="middle">
                        <DownloadButton
                            version={record.name}
                            flavor={Flavor.PRO}
                            platform={OS.DARWIN}/>
                        <DownloadButton
                            version={record.name}
                            flavor={Flavor.PRO}
                            platform={OS.WINDOWS}/>
                    </Space>
                )}
            />
            <Column title={
                <Space size="small">
                    <Avatar src={lddpartnerLogo}/>
                    Partner link
                </Space>
            } key="lddPartnerDownload"
                render={(_, record: Version) => (
                    <Space size="middle">
                        <DownloadButton
                            version={record.name}
                            flavor={Flavor.PARTNER}
                            platform={OS.DARWIN}/>
                        <DownloadButton
                            version={record.name}
                            flavor={Flavor.PARTNER}
                            platform={OS.WINDOWS}/>
                    </Space>
                )}
            />
        </Table>
    )
}