import { ReleaseStatus, Version } from "@ffld/domain";
import { Button, ButtonProps,Typography, Popconfirm, message } from "antd";
import { useState } from "react";
import { updateVersionReleaseStatus } from "../../actions/application-version";
import { AuthorizationError } from "@ffld/access-control";

const {Text} = Typography;

export enum StatusActionType {
    WITHDRAW = "withdraw",
    RERELEASE = "rerelease",
}

const actionToReleaseStatusMapping = {
    [StatusActionType.WITHDRAW]: ReleaseStatus.WITHDRAWN,
    [StatusActionType.RERELEASE]: ReleaseStatus.RERELEASED,
}

export function ActionButton({environmentName, version, statusAction, dispatch}: {environmentName: string, version: Version, statusAction: StatusActionType, dispatch: React.Dispatch<any>}) {
    const [loading, setLoading] = useState(false);
    const props: ButtonProps = statusAction === StatusActionType.WITHDRAW ? {'danger': true} : {'type': 'primary'}

    const onActionTrigger = async () => {
        setLoading(true);
        try {
            await updateVersionReleaseStatus(environmentName, version.name,
                actionToReleaseStatusMapping[statusAction])(dispatch);
        } catch (e) {
            if (e instanceof AuthorizationError) {
                const teamChatURL = process.env.REACT_APP_TEAM_CONTACT_CHAT_URL || "";
                const errorMessage = (
                    <Text>You are not authorized to perform this action. If you need access, please contact
                        <a href={teamChatURL}> LDD Platform Team.</a>
                    </Text>
                )
                message.error(errorMessage);
            } else {
                message.error("An error occurred while performing this action");
            }
        }
        setLoading(false);
    }
    return (
        <Popconfirm
        title={`${statusAction.toLowerCase()} the version`}
        description={`Are you sure you want to ${statusAction.toLowerCase()} this version?`}
        onConfirm={onActionTrigger}
        okText="Yes"
        cancelText="No">
                <Button
                {...props}
                loading={loading}>
                    {statusAction}
                </Button>
        </Popconfirm>
    )
}