import { Select } from "antd";
import { loadEnvironmentVersions } from "../../actions/application-version";
import { ApplicationKey } from "../../contexts/ApplicationContext";
import { State } from "../../views/ApplicationVersions.state";
import { Environment } from "@ffld/domain";


function prioritizeEnvironmentInTheArray(array: Environment[], name: string): Environment[] {
    return array.sort((a, b) => a.name === name ? -1 : b.name === name ? 1 : 0);
}

export function EnvironmentPicker(
    {
        availableEnvironments,
        versionsState,
        setLoading,
        dispatch
    }: {
        availableEnvironments: Environment[],
        versionsState: State,
        setLoading: React.Dispatch<React.SetStateAction<boolean>>,
        dispatch: React.Dispatch<any>,
    }) {
    const onSelection = async (selectedEnvironmentName: string) => {
        setLoading(true);
        await loadEnvironmentVersions(selectedEnvironmentName, ApplicationKey.legoDigitalDesigner)(dispatch);
        setLoading(false);
    };

    const filterOption = (input: string, option?: {label: string; value: string}) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <Select
            showSearch
            placeholder="Select an environment"
            defaultValue={versionsState.currentEnvironment}
            optionFilterProp="children"
            onChange={onSelection}
            filterOption={filterOption}
            style={{width: '12rem'}}
            options={prioritizeEnvironmentInTheArray(availableEnvironments, versionsState.currentEnvironment).map((environment) => {
                return {
                    value: environment.name,
                    label: environment.name,
                }
            })}
        />
    );
}