import { ReleaseStatus } from "..";

enum Flavor {
    PRO = "lddpro",
    PARTNER = "lddpartner",
}

enum ArtifactType {
    EXECUTABLE = "executable",
    DEBUG = "debug",
}

enum OS {
    DARWIN = "darwin",
    WINDOWS = "windows",
}

type VersionHistoryEntry = {
    action: string,
    timestamp: number,
    author: string,
}

type Version = {
    name: string;
    sha: string;
    publishedAt: Date;
    flavor: Flavor;
    environment: string;
    releaseStatus: ReleaseStatus,
    history: VersionHistoryEntry[],
    updatedBy: string,
}

export { ArtifactType, Flavor, OS };
export type { Version, VersionHistoryEntry };