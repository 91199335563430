import React, {useEffect} from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Result } from 'antd';
import { MsalProvider, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { IPublicClientApplication, InteractionStatus, InteractionType } from "@azure/msal-browser";
import { ApplicationContainer } from '@ffld/loader'
import './App.css';
import { ApplicationContext, ApplicationKey } from './contexts/ApplicationContext';
import Loading from './components/Loading';
import CoreLayout from './components/Layout';
import { SecuredComponent } from './components/Authorization';
import Root from './components/Root';
import { EnvironmentsPage } from './views/Environments';
import { EnvironmentLauncherVersionsPage } from './views/LauncherVersions';
import { VersionManagementPage } from './views/ApplicationVersions';
import LoadingAnimation from "./components/Loading";
import { msalRequest } from './authConfig';

const DEFAULT_ENVIRONMENT = "release";

const router = createBrowserRouter([
  {
      path: "/",
      element: <CoreLayout />,
      errorElement: <Result status="500" title="Oopsie!!!" subTitle="There are some problems with loading the page."/>,
      children: [
          {
            index: true,
            element: <Root />
          },
          {
            path: "/version-management",
            element: 
              <SecuredComponent>
                <VersionManagementPage applicationName='lddapp' environmentName={DEFAULT_ENVIRONMENT}/>
              </SecuredComponent>,
            children: []
          },
          {
              path: `${ApplicationKey.lddlauncher}`,
              element: <EnvironmentsPage applicationName={ApplicationKey.lddlauncher}/>,
              children: [
              ]
          },
          {
            path: `${ApplicationKey.lddlauncher}/:environmentName`,
            element: <EnvironmentLauncherVersionsPage applicationName={ApplicationKey.lddlauncher}/>,
          }
      ]
  },
]);

type AppProps = {
  msalInstance: IPublicClientApplication
};

function App({msalInstance}: AppProps) {
  return (
        <MsalProvider instance={msalInstance}>
          <AuthenticatedApp/>
        </MsalProvider>
  );
}

function AuthenticatedApp() {
  const { login, error } = useMsalAuthentication(InteractionType.Silent, msalRequest);
  const { instance, accounts, inProgress } = useMsal();
  
  useEffect(() => {
      if (error) {
          login(InteractionType.Redirect, msalRequest);
      }
  }, [error, login]);

  if (inProgress !== InteractionStatus.None) {
    return LoadingAnimation;
  }
  const applicationAPI = ApplicationContainer.createApplicationAPI(instance, accounts[0]);
  return (
      <React.Fragment>
            <ApplicationContext.Provider value={applicationAPI}>
              <RouterProvider
                router={router}
                fallbackElement={Loading}
              />
          </ApplicationContext.Provider>
      </React.Fragment>
  );
}

export default App;
