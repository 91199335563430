import axios from "axios";
import { IArtifactAdapter, ArtifactType, Environment, Version, Flavor, OS, ReleaseStatus } from "@ffld/domain";
import { MSAL, AuthorizationError} from "@ffld/access-control";

type GetEnvironmentVersionsResponse = {
    versions: {
        id: string,
        version: string,
        publishedAt: number,
        sha: string,
        environment: string,
        flavor: Flavor,
        appname: string,
        location: string,
        history: any,
        modifiedAt: number,
        releaseStatus: ReleaseStatus,
        updatedBy: string,
    }[]
};

type GetLatestVersionResponse = {
    is_update_required: boolean,
    download_urls: string,
    version: string,
};

type GetApplicationNameResponse = {
    environments: string[]
};

type GetDownloadLinkResponse = {
    url: string,
}

enum HTTPMethod {
    GET = "get",
    POST = "post",
    PATCH = "patch",
};

const httpMethodToRequest = {
    [HTTPMethod.GET]: (url, payload, headers) =>  axios.get(url, {params: payload, headers: headers}),
    [HTTPMethod.POST]: (url, payload, headers) => axios.post(url, payload, {headers: headers}),
    [HTTPMethod.PATCH]: (url, payload, headers) => axios.patch(url, payload, {headers: headers}),
};

const getMessageFromError = (error: Error | any): string => error instanceof Error ? error.message : String(error);

class ArtifactRestAdapter implements IArtifactAdapter {
    private accessControl: MSAL;
    GATEWAY_URL = `https://${process.env.REACT_APP_BFLD_ENDPOINT_URL}/v1/apps`;

    constructor(accessControl: MSAL) {
        this.accessControl = accessControl;
    }

    async getHeaders() {
        let token = await this.accessControl.getToken();
        if (!token) {
            return {};
        }
        return {
            "Authorization": "Bearer " + token,
        }
    }

    private async makeRequest(resource: string, httpMethod: HTTPMethod, payload: any = {}): Promise<any> {
        const url = `${this.GATEWAY_URL}/${resource}`;
        try {
            const response = await httpMethodToRequest[httpMethod](url, payload, await this.getHeaders());
            return response.data;
        } catch (error: any) {
            if (error.response && error.response.status === 403) {
                throw new AuthorizationError(`${getMessageFromError(error)}`);
            }
            throw new Error(`Request to resource ${resource} failed! Reason - ${getMessageFromError(error)}`);
        }
    }

    async getApplicationEnvironmentNames(applicationName: string): Promise<string[]> {
        const resource = `${applicationName}/environments`
        const response = await this.makeRequest(resource, HTTPMethod.GET) as GetApplicationNameResponse
        return response.environments;
    }

    async getEnvironmentVersions(environment: Environment): Promise<Version[]> {
        const resource = `${environment.applicationName}/environments/${environment.name}/versions`
        const versionsData = await this.makeRequest(resource, HTTPMethod.GET) as GetEnvironmentVersionsResponse;
        const versions: Version[] = versionsData.versions.map((versionData) => {
            return {
                name: versionData.version,
                sha: versionData.sha,
                publishedAt: new Date(versionData.publishedAt*1000),
                flavor: versionData.flavor,
                releaseStatus: versionData.releaseStatus,
                environment: versionData.environment,
                updatedBy: versionData.updatedBy,
                history: versionData.history,
            } as Version;
        });
        return versions;
    }

    async getLatestVersion(environment: Environment): Promise<string> {
        const resource = `${environment.applicationName}/versions/active`;
        const parameters = {
            environment: environment.name,
        }
        const latestVersionData = await this.makeRequest(resource, HTTPMethod.GET, parameters) as GetLatestVersionResponse;
        return latestVersionData.version;
    }

    async getVersionDownloadLink(environment: Environment, version: string, flavor: Flavor, os: OS, artifactType: ArtifactType): Promise<string> {
        const resource = `${environment.applicationName}/environments/${environment.name}/versions/${version}/${flavor}/${os}/download`
        const parameters = {
            artifact_type: artifactType,
        }
        const downloadData = await this.makeRequest(resource, HTTPMethod.GET, parameters) as GetDownloadLinkResponse;
        return downloadData.url;
    }

    async updateArtifact(environment: Environment, versionName: string, flavor: Flavor, newReleaseStatus: ReleaseStatus): Promise<void> {
        const resource = `${environment.applicationName}/environments/${environment.name}/versions/${versionName}/${flavor}/status`
        await this.makeRequest(resource, HTTPMethod.PATCH, {status: newReleaseStatus});
    }
}

export { ArtifactRestAdapter };