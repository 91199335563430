import { Link } from 'react-router-dom';
import { List, Avatar } from 'antd';
import { Environment } from '@ffld/domain';
import React, { useCallback, useState } from 'react';
import { loadEnvironments } from '../loaders/environments';
import { useEffectWithMSAL } from '../hooks/useEffectWithMSAL';

  
export function EnvironmentsPage({applicationName}: {applicationName: string}) {
    const [environments, setEnvironments] = useState<Environment[]>([]);
    const loadData = useCallback(async () => {
        const fetchedEnvironments = await loadEnvironments({applicationName: applicationName});
        setEnvironments(fetchedEnvironments);
    }, [applicationName]);
    useEffectWithMSAL(loadData);
    return <EnvironmentList environments={environments}/>
}
  
function EnvironmentPreviewListItem(
    {environmentName, orderNumber}: {environmentName: string, orderNumber: number}
) {
    return (
        <List.Item>
        <List.Item.Meta
            avatar={<Avatar src={`https://api.dicebear.com/6.x/shapes/png?seed=${orderNumber}`} />}
            title={<Link to={environmentName}>{environmentName}</Link>}
        />
        </List.Item>
    )
}
  
function EnvironmentList({environments}: {environments: Environment[]}) {
    return (
        <List
        itemLayout='horizontal'
        dataSource={environments}
        renderItem={(environment, index) => (
            <EnvironmentPreviewListItem 
            environmentName={environment.name} 
            orderNumber={index}
            />
        )}
        />
    )
}