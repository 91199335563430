import { useContext } from "react";
import { Button, Typography } from "antd";
import { ArtifactType, Flavor, OS } from "@ffld/domain";
import { ApplicationContext } from "../../contexts/ApplicationContext";
import { IconPlatform } from "./IconPlatform";

const {Text} = Typography;

export function DownloadButton(
    {version, flavor, platform, applicationName, environmentName, artifactType, text = ""}
    : {version: string, flavor: Flavor, platform: OS, applicationName: string, environmentName: string, artifactType: ArtifactType, text?: string}
) {
    const applicationAPI = useContext(ApplicationContext);
    return (
        <Button type="primary" ghost={artifactType === ArtifactType.DEBUG} 
            icon={<IconPlatform platform={platform}/>}
            size="large"
            onClick={async () => {
                const link = await applicationAPI.getVersionDownloadLink(
                    {name: environmentName, applicationName: applicationName},
                    version, flavor, platform, artifactType
                );
                window.location.href = link;
            }}
        >{text === "" ? "" : <Text style={{fontSize: "12px"}}>{text}</Text>}</Button>
    )
}