import { BrowserCacheLocation, RedirectRequest } from "@azure/msal-browser"

export const MSALConfiguration = {
    auth: {
        clientId: process.env.REACT_APP_ENTRA_ID_CLIENT_ID || "",
        authority: 'https://login.microsoftonline.com/1d063515-6cad-4195-9486-ea65df456faa',
        redirectUri: process.env.REACT_APP_URL || ""
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage // "sessionStorage"
    },	
};

export const msalRequest: RedirectRequest = {
    scopes: [`api://${process.env.REACT_APP_ENTRA_ID_CLIENT_ID}/user_impersonation`],
};