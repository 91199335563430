import { ArtifactType, Flavor, OS, ReleaseStatus, Version } from "@ffld/domain";
import { Badge, Space, Table, Typography } from "antd";
import Column from "antd/es/table/Column";
import { ActionButton, StatusActionType } from "./ActionButton";
import { State } from "../../views/ApplicationVersions.state";
import React, { useContext } from "react";
import { ApplicationContext, ApplicationKey } from "../../contexts/ApplicationContext";
import { DownloadButton } from "./DownloadButton";

const {Text} = Typography;

function filterVersions(state: State): Version[] {
    return state.allVersions.filter((v) => {
        return state.filters.includes(v.releaseStatus) && (state.searchTerm === '' || v.name.includes(state.searchTerm))
    })
}

function VersionNameText({versionName, latestVersionName, versions}: {versionName: string, latestVersionName: string, versions: Version[]}) {
    // This function is used to render the version name in the table
    const version = versions.find(v => v.sha === latestVersionName);
    if (versionName === version?.name) {
        return (
            <Space>
                <Text>{versionName}</Text>
                <Badge color="blue" count="Current"></Badge>
            </Space>
        )
    } else {
        return <Text>{versionName}</Text>
    }
}


export function VersionsTable({versionsState, loadingState, dispatch}: {versionsState: State, loadingState: boolean, dispatch: React.Dispatch<any>}) {
    const applicationAPI = useContext(ApplicationContext);
    const showDebugFiles = versionsState.currentEnvironment === "release";

    return (
        <Table dataSource={filterVersions(versionsState)} bordered loading={loadingState}>
            <Column title="Version" dataIndex="name" key="name" render={(versionName: string) => (
                <VersionNameText versionName={versionName} latestVersionName={versionsState.latestVersionName} versions={versionsState.allVersions}/>
            )}/>
            <Column title="Published Date" dataIndex="publishedAt" key="publishedAt"
                defaultSortOrder="descend"
                sorter={(v1: Version, v2: Version) => v1.publishedAt.getTime() - v2.publishedAt.getTime()}
                sortDirections={['descend']}
                render={(date: Date) => date.toLocaleString()}
            />
            <Column title="Status" dataIndex="releaseStatus" key="releaseStatus" />
            <Column title="Actions" key="actions" align="center" width="10rem"
                render={(_: any, record: Version) => (
                    <Space size="small">
                        <ActionButton
                            environmentName={versionsState.currentEnvironment}
                            version={record}
                            statusAction={
                                record.releaseStatus === ReleaseStatus.WITHDRAWN ?
                                StatusActionType.RERELEASE : StatusActionType.WITHDRAW
                            }
                            dispatch={dispatch}
                        ></ActionButton>
                    </Space>
                )}
            />
            <Column title="Last Action Performed" key="previousAction"
                render={(_: any, record: Version) => (
                    <>
                        <Text strong>Action Type: </Text>
                        <Text>{applicationAPI.getLastActionPerformed(record)}</Text>
                        <Text strong> By: </Text>
                        <Text>{record.updatedBy || "Unknown"}</Text>
                    </>
                )}
            />
            <Column title={"Download Executable" + (showDebugFiles ? "/Debug Files" : "")} key="download"
                render={(_, record: Version) => (
                    <Space size="middle">
                        {[
                            { platform: OS.DARWIN, artifactType: ArtifactType.EXECUTABLE },
                            { platform: OS.DARWIN, artifactType: ArtifactType.DEBUG, text: "d.Sym" },
                            { platform: OS.WINDOWS, artifactType: ArtifactType.EXECUTABLE },
                            { platform: OS.WINDOWS, artifactType: ArtifactType.DEBUG, text: ".pdb" },
                        ].map(({ platform, artifactType, text }) => {
                            if (!showDebugFiles && artifactType === ArtifactType.DEBUG) return null;
                            return (
                                <DownloadButton
                                    key={`${platform}-${artifactType}`}
                                    version={record.name}
                                    flavor={Flavor.PRO}
                                    applicationName={ApplicationKey.legoDigitalDesigner}
                                    environmentName={versionsState.currentEnvironment}
                                    platform={platform}
                                    artifactType={artifactType}
                                    text={text}
                                />
                        )})}
                    </Space>
                )} 
            />
        </Table>
    )
};