import { ReleaseStatus, Environment } from "@ffld/domain";
import { Input, Space, Typography } from "antd";
import React, { useCallback, useReducer, useState } from "react";
import { loadEnvironmentVersions, searchVersions } from "../actions/application-version";
import { versionReducer } from "../reducers/versionReducer";
import { EnvironmentPicker } from "../components/application-version/EnvironmentPicker";
import { VersionStatusFilter } from "../components/application-version/VersionStatusFilter";
import { VersionsTable } from "../components/application-version/VersionsTable";
import { State } from "./ApplicationVersions.state";
import { ApplicationContainer } from "@ffld/loader";
import { useEffectWithMSAL } from "../hooks/useEffectWithMSAL";

const {Text} = Typography;

export function VersionManagementPage({environmentName, applicationName}: {environmentName: string, applicationName: string}) {
    const initialState: State = {
        currentEnvironment: environmentName,
        allVersions: [],
        latestVersionName: "",
        filters: [ReleaseStatus.RELEASED, ReleaseStatus.RERELEASED, ReleaseStatus.WITHDRAWN],
        searchTerm: '',
    };

    const [versionsState, dispatch] = useReducer(versionReducer, initialState);
    const [loading, setLoading] = useState(false);
    const [availableEnvironments, setAvailableEnvironments] = useState<Environment[]>([]);

    const loadData = useCallback(async () => {
        const loadEnvironments = async () => {
            const environments = await ApplicationContainer.getApplicationAPI().getApplicationEnvironments(applicationName);
            setAvailableEnvironments(environments)
        };
        const loadEnvironmentVersionsData = async () => {
            setLoading(true);
            await loadEnvironmentVersions(environmentName, applicationName)(dispatch);
            setLoading(false);
        };
        await Promise.all([loadEnvironments(), loadEnvironmentVersionsData()]);
    }, [applicationName, environmentName]);
    useEffectWithMSAL(loadData); 

    function VersionFilterBar() {
        return (
            <Space size="middle" style={{display: 'flex',margin: '10px'}}>
                <Text ellipsis={true} strong>Current Environment:</Text>
                <EnvironmentPicker availableEnvironments={availableEnvironments} versionsState={versionsState} setLoading={setLoading} dispatch={dispatch}/>
                <Text ellipsis={true} strong>Filters:</Text>
                <VersionSearch />
                <VersionStatusFilter statusToFilter={ReleaseStatus.RELEASED} versionsState={versionsState} dispatch={dispatch}/>
                <VersionStatusFilter statusToFilter={ReleaseStatus.RERELEASED} versionsState={versionsState} dispatch={dispatch}/>
                <VersionStatusFilter statusToFilter={ReleaseStatus.WITHDRAWN} versionsState={versionsState} dispatch={dispatch}/>
            </Space>
        )
    };

    function VersionSearch() {
        const handleSearch = (e: any) => {
            searchVersions(e.target.value)(dispatch);
        }
        return (
            <Input placeholder="Version Name" autoFocus defaultValue={versionsState.searchTerm} onChange={handleSearch}/>
        )
    }

    return (
        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
            <VersionFilterBar />
            <VersionsTable versionsState={versionsState} loadingState={loading} dispatch={dispatch}/>
        </Space>
    )
}