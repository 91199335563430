import { ReleaseStatus } from "@ffld/domain";
import React from "react";
import { DispatchEventType, VersionAction } from "../reducers/versionReducer";
import { loadVersions } from "../loaders/versions";
import { ApplicationKey } from "../contexts/ApplicationContext";
import { ApplicationContainer } from "@ffld/loader";

export function filterVersionsByStatus(status: ReleaseStatus, toShow: boolean) {
    return (dispatch: React.Dispatch<VersionAction>) => {
        dispatch({
            type: toShow ? DispatchEventType.SHOW : DispatchEventType.HIDE,
            payload: {
                releaseStatus: status,
            },
        });
    }
}

export function searchVersions(searchTerm: string) {
    return (dispatch: React.Dispatch<VersionAction>) => {
        dispatch({
            type: DispatchEventType.SEARCH,
            payload: {
                searchTerm: searchTerm,
            } 
        })
    }
}

export function loadEnvironmentVersions(environmentName: string, applicationName: string) {
    return async (dispatch: React.Dispatch<VersionAction>) => {
        const environmentVersionData = await loadVersions({
            applicationName: applicationName,
            environmentName: environmentName,
          });
        dispatch({
            type: DispatchEventType.LOAD_VERSIONS,
            payload: {
                environmentName: environmentName,
                versions: environmentVersionData.versions,
                latestVersionName: environmentVersionData.latestVersionName,
            },
        });
    }
}

export function updateVersionReleaseStatus(environmentName: string, versionName: string, newReleaseStatus: ReleaseStatus) {
    return async (dispatch: React.Dispatch<VersionAction>) => {
        await ApplicationContainer.getApplicationAPI().updateVersionReleaseStatus({
            name: environmentName,
            applicationName: ApplicationKey.legoDigitalDesigner,
        }, versionName, newReleaseStatus);
        dispatch({
            type: DispatchEventType.CHANGE_RELEASE_STATUS,
            payload: {
                versionName: versionName,
                releaseStatus: newReleaseStatus,
            },
        });
    }
}