import { Version, EnvironmentVersions } from "@ffld/domain";
import { ApplicationContainer } from "@ffld/loader";

export async function loadVersions(
    {applicationName, environmentName}: {applicationName: string, environmentName: string}
): Promise<EnvironmentVersions> {
    let environmentVersionsData = await ApplicationContainer.getApplicationAPI().getEnvironmentVersions(
        {name: environmentName, applicationName: applicationName}
    );
    environmentVersionsData.versions = filterUniqueArtifacts(environmentVersionsData.versions);
    return environmentVersionsData;
}

function filterUniqueArtifacts(versions: Version[]): Version[] {
    const uniqueVersions = new Map<string, Version>();
    for (let artifact of versions) {
       uniqueVersions.set(artifact.name, artifact);
    }

    const uniqueArtifacts = Array.from(uniqueVersions.values());
    return uniqueArtifacts;
}