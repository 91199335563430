import { ReleaseStatus, Version } from "@ffld/domain";
import { State } from "../views/ApplicationVersions.state";

export enum DispatchEventType {
    HIDE = "hide",
    SHOW = "show",
    CHANGE_RELEASE_STATUS = "change-release-status",
    LOAD_VERSIONS = "load-versions",
    SEARCH = "search",
};

interface VersionActionPayload {
    versionName?: string,
    releaseStatus?: ReleaseStatus,
    versions?: Version[],
    environmentName?: string,
    searchTerm?: string,
    environmentVersionData?: string,
    latestVersionName?: string,
}

export interface VersionAction {
    type: DispatchEventType,
    payload: VersionActionPayload,
}

export function versionReducer(state: State, action: VersionAction): State {
    switch (action.type) {
        case DispatchEventType.HIDE: {
            return {...state, filters: state.filters.filter(
                (filter) => filter !== action.payload.releaseStatus
            )};
        }
        case DispatchEventType.SHOW: {
            if (action.payload.releaseStatus === undefined) {
                return state;
            }
            return {...state, filters: [...state.filters, action.payload.releaseStatus]};
        }
        case DispatchEventType.CHANGE_RELEASE_STATUS: {
            if (action.payload.releaseStatus === undefined) {
                return state;
            }
            const versions = state.allVersions.map((v: Version) => {
                if (v.name === action.payload.versionName) {
                    return {...v, releaseStatus: action.payload.releaseStatus} as Version;
                }
                return v;
            });
            return {
                ...state,
                allVersions: versions,
            }
        }
        case DispatchEventType.LOAD_VERSIONS: {
            return {
                ...state,
                currentEnvironment: action.payload.environmentName || "",
                allVersions: action.payload.versions || [],
                latestVersionName: action.payload.latestVersionName || "",
            };
        }
        case DispatchEventType.SEARCH: {
            return {
                ...state,
                searchTerm: action.payload.searchTerm || '',
            }
        }
        default: {
            throw Error(`Unknown action: ${action.type}`);
        }
    }
}